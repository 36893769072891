<template>
    <div class="DraftList">
        <div class="DraftList__container">
            <div style="font-size: 12px; color: #969799;">点击可继续编辑开通详情</div>
            <div class="DraftList__body">
                <my-scroll-list ref="list" :option="option">
                    <template #item="{item}">
                        <div class="DraftList__item Item" @click="onOpenOrder(item)">
                            <div class="Item__container">
                                <div class="Item__title">
                                    <span style="font-size: 16px; font-weight: 600;">{{ item.batchName }}</span>
                                    <span style="font-size: 12px; color: #3C8FFF; margin-left: 4px;">新开提单</span>
                                </div>
                                <div class="Item__desc">
                                    <div class="Item__descLabel">企业名称</div>
                                    <div class="Item__descValue">
                                        {{ getCompanyName(item.trashData) }}
                                    </div>
                                </div>
                                <div class="Item__desc">
                                    <div class="Item__descLabel">保存时间</div>
                                    <div class="Item__descValue">{{ item.createTime }}</div>
                                </div>
                            </div>
                            <van-icon :size="20"
                                      color="#EE0A24"
                                      name="jianshao"
                                      class-prefix="iconfont icon"
                                      @click.stop="onDelete(item)"
                            />
                        </div>
                    </template>
                </my-scroll-list>
            </div>
        </div>
        <div class="DraftList__search">
            <van-search v-model="option.query.batchName" placeholder="请输入400号码，支持模糊搜索" @search="onSearch" />
        </div>
    </div>
</template>

<script>
    import {MyScrollList} from "@/components/MyPlugins";
    import {draftList, delDraft} from "@/api/openOrder";

    export default {
        name: "DraftList",
        components: {MyScrollList},
        data() {
            return {
                option: {
                    api: draftList,
                    query: {
                        batchName: '',
                        onlyFail: 1
                    }
                },
            }
        },
        methods: {
            onSearch() {
                this.$refs.list.onSearch();
            },
            onOpenOrder(item) {
                let {trashCode} = item;
                this.$router.push({path: '/open-order', query: {trashCode}});
            },
            onDelete(item) {
                let {trashCode} = item;
                this.$dialog.confirm({
                    title: '删除草稿',
                    message: '确定删除当前草稿吗？',
                }).then(() => {
                    this.$toast.loading({message: '正在删除中，请稍后...', forbidClick: true, duration: 0});
                    delDraft({trashCode}).then(() => {
                        this.$toast.clear();
                        this.onSearch();
                    });
                });
            },
            getCompanyName(data) {
                if (!data) return '';
                let trashData = JSON.parse(data);

                return trashData.user_info && trashData.user_info.company_name;
            }
        }
    }
</script>

<style lang="scss" scoped>
    .DraftList {
        height: 100vh;
        background: #F7F8FA;
        display: flex;
        flex-direction: column;
        &__container {
            flex: 1;
            padding: 10px;
            display: flex;
            flex-direction: column;
            overflow: hidden;
        }
        &__body {
            flex: 1;
            overflow: auto;
            margin-top: 10px;
        }
        &__item {
            border-radius: 4px;
            background: #FFFFFF;
            margin-bottom: 10px;
            padding: 16px;
            &:last-child {
                margin-bottom: 0;
            }
        }
        .Item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            &__title {
                line-height: 30px;
                margin-bottom: 4px;
                display: flex;
                align-items: center;
            }
            &__desc {
                display: flex;
                font-size: 12px;
                gap: 0 8px;
                line-height: 18px;
                margin-bottom: 4px;
                &:last-child {
                    margin-bottom: 0;
                }
                &Label {
                    width: 48px;
                    color: #969799;
                }
                &Value {
                    flex: 1;
                    color: #323233;
                }
            }
        }
    }
</style>
